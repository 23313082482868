<template>
    <profile-layout>
        <template #header>
            <div
                class="arrowWrap"
                @click="toProfile"
            >
                <arrow />
            </div>
            Change user nick name
        </template>
        <template #content>
            <div class="input-field">
                <form-input
                    v-model="nickname"
                    legend="Enter new nick name"
                    :errors="nameErrorText || null"
                    tabindex="1"
                    type="text"
                    @input="inputNameHanderler"
                />
            </div>
            <password-modal
                :name="'password-test-name'"
                :title="'Change name'"
            />
        </template>
        <template #footer>
            <custom-button
                default
                class="button btn-base_colored"
                :type="'submit'"
                tabindex="3"
                width="100%"
                @on-btn-click="validationData"
            >
                Submit
            </custom-button>
        </template>
    </profile-layout>
</template>

<script>
import {
    ACTION_GET_CURRENT_USER
} from '@/store/modules/auth/action-types'
import { createNamespacedHelpers, mapState } from 'vuex'

import { changeUserNickName } from '@/services/profileService';
import ProfileLayout from '@/layouts/ProfileLayout'
import PasswordModal from '@/components/modals/PasswordModal';
import Arrow from '@/icons/Arrow'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth')
export default {
    name: 'ChangeUserNickName',
    components: { ProfileLayout, Arrow, PasswordModal },
    data() {
        return {
            nickname: '',
            nameErrorText: '',
            lastNameErrorText: ''
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    },
    activated() {
        document.querySelector('input')?.focus()
        this.$bus.$on('confirm', this.submit)
    },
    deactivated() {
        this.$bus.$off('confirm', this.submit)
    },
    methods: {
        ...mapAdminActions([
            ACTION_GET_CURRENT_USER
        ]),
        toProfile() {
            this.$router.push({ name: 'user-profile' })
        },
        reset() {
            this.nickname = '';
        },
        validationData() {
            this.nameErrorText = ''

            if (!this.nickname) {
                this.nameErrorText = 'NickName is a required field'
            }
            if (this.nickname === this.user.nickname) {
                this.nameErrorText = 'The data has not changed'
            }
            if (this.nickname.length >= 30 || this.nickname.length < 2) {
                this.nameErrorText = 'Nickname length must be more than 2 and less than 30 characters'
            }
            if (this.nameErrorText || this.lastNameErrorText) {
                return
            }

            this.$bus.$emit('openModal', 'password-test-name')
        },
        submit(name) {
            if (name !== 'password-test-name') { return }
            changeUserNickName({ nickname: this.nickname }).then(async () => {
                await this[ACTION_GET_CURRENT_USER]()
                this.$bus.$emit('showSnackBar', 'Your nick name was changed', 'success')
                this.reset()
                this.toProfile()
            }).catch((e) => {
                this.$bus.$emit('showSnackBar', e.response?.data?.message || 'Invalid data', 'error')
            })
        },
        inputNameHanderler() {
            this.nameErrorText = ''
        }
    }
}
</script>

<style scoped>

</style>
